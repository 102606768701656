<template>
  <static-template>
    <h1>Terms and Conditions</h1>
    <br />
    <br />
    <h2>{{ $t("tncTitle") }}</h2>
    <br />
    <h3>{{ $t("tncIntro") }}</h3>
    <br />
    <p>
      {{ $t("tncIntro1") }}
    </p>
    <p>
      {{ $t("tncIntro2") }}
    </p>
    <br />
    <h2>{{ $t("tncA1") }}</h2>
    <br />
    <p>{{ $t("tncA1p1") }}</p>
    <br />
    <ol>
      <li>{{ $t("tncA1l1p1") }}</li>
      <p>{{ $t("tncA1l1p2") }}</p>
      <p>{{ $t("tncA1l1p3") }}</p>
      <p>{{ $t("tncA1l1p4") }}</p>
      <li>{{ $t("tncA1l2") }}</li>
      <li>{{ $t("tncA1l3") }}</li>
      <li>{{ $t("tncA1l4") }}</li>
      <li>{{ $t("tncA1l5") }}</li>
      <li>{{ $t("tncA1l6") }}</li>
      <li>{{ $t("tncA1l7") }}</li>
      <li>{{ $t("tncA1l8") }}</li>
      <li>{{ $t("tncA1l9") }}</li>
      <li>{{ $t("tncA1l10") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA2") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA2l1") }}</li>
      <li>{{ $t("tncA2l2") }}</li>
      <li>{{ $t("tncA2l3") }}</li>
      <li>{{ $t("tncA2l4") }}</li>
      <li>{{ $t("tncA2l5") }}</li>
      <li>{{ $t("tncA2l6") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA3") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA3l1") }}</li>
      <li>{{ $t("tncA3l2") }}</li>
      <li>{{ $t("tncA3l3") }}</li>
      <li>{{ $t("tncA3l4") }}</li>
      <li>{{ $t("tncA3l5") }}</li>
      <li>{{ $t("tncA3l6") }}</li>
      <li>{{ $t("tncA3l7") }}</li>
      <li>{{ $t("tncA3l8") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA4") }}</h2>
    <br />
    <p>{{ $t("tncA4p1") }}</p>
    <p>{{ $t("tncA4l1") }}</p>
    <p>{{ $t("tncA4l2") }}</p>
    <p>{{ $t("tncA4l3") }}</p>
    <p>{{ $t("tncA4l4") }}</p>
    <p>{{ $t("tncA4l5") }}</p>
    <p>{{ $t("tncA4l6") }}</p>
    <p>{{ $t("tncA4l7") }}</p>
    <p>{{ $t("tncA4l8") }}</p>
    <p>{{ $t("tncA4l9") }}</p>
    <p>{{ $t("tncA4l10") }}</p>
    <p>{{ $t("tncA4l11") }}</p>
    <p>{{ $t("tncA4l12") }}</p>

    <br />
    <h2>{{ $t("tncA5") }}</h2>
    <br />
    <p>{{ $t("tncA5p1") }}</p>
    <br />

    <h2>{{ $t("tncA6") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA6l1") }}</li>
      <li>{{ $t("tncA6l2") }}</li>
      <li>{{ $t("tncA6l3") }}</li>
      <li>{{ $t("tncA6l4") }}</li>
    </ol>

    <br />

    <h2>{{ $t("tncA7") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA7l1") }}</li>
      <p>{{ $t("tncA7l1-1") }}</p>
      <p>{{ $t("tncA7l1-2") }}</p>
      <p>{{ $t("tncA7l1-3") }}</p>
      <p>{{ $t("tncA7l1-4") }}</p>
      <p>{{ $t("tncA7l1-5") }}</p>
      <p>{{ $t("tncA7l1-6") }}</p>
      <p>{{ $t("tncA7l1-7") }}</p>
      <p>{{ $t("tncA7l1-8") }}</p>
      <li>{{ $t("tncA7l2") }}</li>
      <li>{{ $t("tncA7l3") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA8") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA8l1") }}</li>
      <li>{{ $t("tncA8l2") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA9") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA9l1") }}</li>
      <li>{{ $t("tncA9l2") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA10") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA10l1") }}</li>
      <li>{{ $t("tncA10l2") }}</li>
      <li>{{ $t("tncA10l3") }}</li>
      <li>{{ $t("tncA10l4") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA11") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA11l1") }}</li>
      <li>{{ $t("tncA11l2") }}</li>
      <li>{{ $t("tncA11l3") }}</li>
      <li>{{ $t("tncA11l4") }}</li>
      <li>{{ $t("tncA11l5") }}</li>
      <li>{{ $t("tncA11l6") }}</li>
      <li>{{ $t("tncA11l7") }}</li>
      <li>{{ $t("tncA11l8") }}</li>
      <li>{{ $t("tncA11l9") }}</li>
      <li>{{ $t("tncA11l10") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA12") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA12l1") }}</li>
      <li>{{ $t("tncA12l2") }}</li>
      <li>{{ $t("tncA12l3") }}</li>
      <li>{{ $t("tncA12l4") }}</li>
      <li>{{ $t("tncA12l5") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA13") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA13l1") }}</li>
      <li>{{ $t("tncA13l2") }}</li>
      <li>{{ $t("tncA13l3") }}</li>
      <li>{{ $t("tncA13l4") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA14") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA14l1") }}</li>
      <li>{{ $t("tncA14l2") }}</li>
      <li>{{ $t("tncA14l3") }}</li>
      <li>{{ $t("tncA14l4") }}</li>
      <li>{{ $t("tncA14l5") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA15") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA15l1") }}</li>
      <li>{{ $t("tncA15l2") }}</li>
      <li>{{ $t("tncA15l3") }}</li>
      <li>{{ $t("tncA15l4") }}</li>
      <li>{{ $t("tncA15l5") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA16") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA16l1") }}</li>
      <li>{{ $t("tncA16l2") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA17") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA17l1") }}</li>
      <li>{{ $t("tncA17l2") }}</li>
      <li>{{ $t("tncA17l3") }}</li>
      <li>{{ $t("tncA17l4") }}</li>
      <li>{{ $t("tncA17l5") }}</li>
      <li>{{ $t("tncA17l6") }}</li>
      <li>{{ $t("tncA17l7") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA18") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA18l1") }}</li>
      <li>{{ $t("tncA18l2") }}</li>
      <li>{{ $t("tncA18l3") }}</li>
      <p>{{ $t("tncA18l3-1") }}</p>
      <p>{{ $t("tncA18l3-2") }}</p>
      <p>{{ $t("tncA18l3-3") }}</p>
      <li>{{ $t("tncA18l4") }}</li>
      <li>{{ $t("tncA18l5") }}</li>
      <li>{{ $t("tncA18l6") }}</li>
      <li>{{ $t("tncA18l7") }}</li>
      <li>{{ $t("tncA18l8") }}</li>
      <li>{{ $t("tncA18l9") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA19") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA19l1") }}</li>
      <li>{{ $t("tncA19l2p1") }}</li>
      <p>{{ $t("tncA19l2p2") }}</p>
      <li>{{ $t("tncA19l3") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA20") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA20l1") }}</li>
      <li>{{ $t("tncA20l2") }}</li>
      <li>{{ $t("tncA20l3") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA21") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA21l1") }}</li>
      <li>{{ $t("tncA21l2") }}</li>
      <li>{{ $t("tncA21l3") }}</li>
      <li>{{ $t("tncA21l4") }}</li>
      <p>{{ $t("tncA21l4-1") }}</p>
      <p>{{ $t("tncA21l4-2") }}</p>
      <p>{{ $t("tncA21l4-3") }}</p>
      <p>{{ $t("tncA21l4-4") }}</p>
      <p>{{ $t("tncA21l4-5") }}</p>
      <p>{{ $t("tncA21l4-6") }}</p>
    </ol>

    <br />
    <h2>{{ $t("tncA22") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA22l1") }}</li>
      <li>{{ $t("tncA22l2") }}</li>
      <li>{{ $t("tncA22l3p1") }}</li>
      <p>{{ $t("tncA22l3p2") }}</p>
      <p>{{ $t("tncA22l3p3") }}</p>
      <p>{{ $t("tncA22l3p4") }}</p>
      <p>{{ $t("tncA22l3p5") }}</p>
      <p>{{ $t("tncA22l3p6") }}</p>
      <p>{{ $t("tncA22l3p7") }}</p>
      <li>{{ $t("tncA22l4") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA23") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA23l1") }}</li>
      <p>{{ $t("tncA23l1-1") }}</p>
      <p>{{ $t("tncA23l1-2") }}</p>
      <p>{{ $t("tncA23l1-3") }}</p>
      <p>{{ $t("tncA23l1-4") }}</p>
      <p>{{ $t("tncA23l1-5") }}</p>
      <p>{{ $t("tncA23l1-6") }}</p>
      <p>{{ $t("tncA23l1-7") }}</p>
      <p>{{ $t("tncA23l1-8") }}</p>
      <p>{{ $t("tncA23l1-9") }}</p>
      <p>{{ $t("tncA23l1-10") }}</p>
      <p>{{ $t("tncA23l1-11") }}</p>
      <p>{{ $t("tncA23l1-12") }}</p>
      <p>{{ $t("tncA23l1-13") }}</p>
      <p>{{ $t("tncA23l1-14") }}</p>
      <p>{{ $t("tncA23l1-15") }}</p>
      <p>{{ $t("tncA23l1-16") }}</p>
      <p>{{ $t("tncA23l1-17") }}</p>
      <p>{{ $t("tncA23l1-18") }}</p>
      <p>{{ $t("tncA23l1-19") }}</p>
      <p>{{ $t("tncA23l1-20") }}</p>
      <p>{{ $t("tncA23l1-21") }}</p>
      <p>{{ $t("tncA23l1-22") }}</p>
      <p>{{ $t("tncA23l1-23") }}</p>
      <p>{{ $t("tncA23l1-24") }}</p>
      <p>{{ $t("tncA23l1-25") }}</p>
      <p>{{ $t("tncA23l1-26") }}</p>
      <p>{{ $t("tncA23l1-27") }}</p>
      <p>{{ $t("tncA23l1-28") }}</p>
      <li>{{ $t("tncA23l2") }}</li>
      <p>{{ $t("tncA23l2-1") }}</p>
      <p>{{ $t("tncA23l2-2") }}</p>
      <p>{{ $t("tncA23l2-3") }}</p>
      <p>{{ $t("tncA23l2-4") }}</p>
      <p>{{ $t("tncA23l2-5") }}</p>
      <p>{{ $t("tncA23l2-6") }}</p>
    </ol>

    <br />
    <h2>{{ $t("tncA24") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA24l1") }}</li>
      <li>{{ $t("tncA24l2") }}</li>
      <li>{{ $t("tncA24l3") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA25") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA25l1") }}</li>
      <li>{{ $t("tncA25l2") }}</li>
      <li>{{ $t("tncA25l3") }}</li>
      <li>{{ $t("tncA25l4") }}</li>
      <li>{{ $t("tncA25l5") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA26") }}</h2>
    <br />
    <p>{{ $t("tncA26p1") }}</p>

    <br />
    <h2>{{ $t("tncA27") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA27l1") }}</li>
      <li>{{ $t("tncA27l2") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA28") }}</h2>
    <br />
    <p>{{ $t("tncA28p1") }}</p>
    <p>{{ $t("tncA28l1") }}</p>
    <p>{{ $t("tncA28l2") }}</p>
    <p>{{ $t("tncA28l3") }}</p>
    <p>{{ $t("tncA28l4") }}</p>
    <p>{{ $t("tncA28l5") }}</p>
    <p>{{ $t("tncA28l6") }}</p>
    <p>{{ $t("tncA28l7") }}</p>
    <p>{{ $t("tncA28l8") }}</p>

    <br />
    <h2>{{ $t("tncA29") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA29l1") }}</li>
      <li>{{ $t("tncA29l2") }}</li>
      <li>{{ $t("tncA29l3") }}</li>
      <li>{{ $t("tncA29l4") }}</li>
      <li>{{ $t("tncA29l5") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA30") }}</h2>
    <br />
    <p>{{ $t("tncA30p1") }}</p>
    <p>{{ $t("tncA30l1") }}</p>
    <p>{{ $t("tncA30l2") }}</p>
    <p>{{ $t("tncA30l3") }}</p>
    <p>{{ $t("tncA30l4") }}</p>
    <p>{{ $t("tncA30l5") }}</p>

    <br />
    <h2>{{ $t("tncA31") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA31l1") }}</li>
      <li>{{ $t("tncA31l2") }}</li>
      <li>{{ $t("tncA31l3") }}</li>
      <li>{{ $t("tncA31l4") }}</li>
      <li>{{ $t("tncA31l5") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA32") }}</h2>
    <br />
    <p>{{ $t("tncA32p1") }}</p>

    <br />
    <h2>{{ $t("tncA33") }}</h2>
    <br />
    <p>{{ $t("tncA33p1") }}</p>

    <br />
    <h2>{{ $t("tncA34") }}</h2>
    <br />
    <p>{{ $t("tncA34p1") }}</p>

    <br />
    <h2>{{ $t("tncA35") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA35l1") }}</li>
      <li>{{ $t("tncA35l2") }}</li>
      <li>{{ $t("tncA35l3") }}</li>
      <li>{{ $t("tncA35l4") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA36") }}</h2>
    <br />
    <p>{{ $t("tncA36p1") }}</p>

    <br />
    <h2>{{ $t("tncA37") }}</h2>
    <br />
    <ol>
      <li>{{ $t("tncA37l1") }}</li>
      <li>{{ $t("tncA37l2") }}</li>
    </ol>

    <br />
    <h2>{{ $t("tncA38") }}</h2>
    <br />
    <p>{{ $t("tncA38p1") }}</p>

    <br />
    <h2>{{ $t("tncA39") }}</h2>
    <br />
    <p>{{ $t("tncA39p1") }}</p>
    <br />
    <br />

    {{ $t("tncDate") }}
  </static-template>
</template>

<script>
export default {
  name: "TermAndCondPage",
};
</script>
